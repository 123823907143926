html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-container {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .topbar {
  width: 100%;
} */

.footer {
  /* width: calc(100% - 250px); */
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  position: relative;
  left: 250px;
}