:root {
    /* Light Theme Colors */
    --primary-light-400: #e6e6e6;
    --primary-light-500: #f0f0f0;
    --greenAccent-light-500: #1DB954;
    --greenAccent-light-600: #d1ecd5;
    --grey-light-100: #000000;
    --red-light-500: #ff0000;

    /* Dark Theme Colors */
    --primary-dark-400: #333333;
    --primary-dark-500: #444444;
    --greenAccent-dark-500: #1DB954;
    --greenAccent-dark-600: #1DB954;
    --grey-dark-100: #ffffff;
    --red-dark-500: #ff0000;
}

/* Stock.css */
.canvas-container {
    position: relative;
    width: 100%;
    height: 100%;
}

