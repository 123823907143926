.footer-container {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--footer-text-color);
    background-color: var(--footer-background-color);
    font-family: Arial, sans-serif;
    width: calc(100% - 40px);
    overflow-x: hidden;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
    flex-wrap: wrap;
}

.footer-branding {
    flex: 1;
    max-width: 350px;
}

.footer-logo {
    width: 180px;
    height: 50px;
    margin-bottom: 15px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.social-icons a {
    font-size: 20px;
    color: var(--footer-icon-color);
    transition: color 0.3s;
}

.social-icons a:hover {
    color: var(--footer-link-hover-color);
}

.footer-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    max-width: 500px;
}

.footer-links {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-links ul li {
    margin-bottom: 10px;
}

.footer-links ul li a {
    color: var(--footer-link-color);
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links ul li a:hover {
    color: var(--footer-link-hover-color);
}

.contact-us {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.contact-us-button {
    background-color: white;
    color: black;
    border: none;
    padding: 15px 30px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s, color 0.3s;
}

.contact-us-button:hover {
    background-color: var(--footer-link-hover-color);
    color: white;
}

.footer-bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding-top: 20px;
    border-top: 1px solid var(--footer-input-border);
    text-align: center;
}

.footer-bottom p {
    margin: 0;
    font-size: 14px;
}

.footer-bottom a:hover {
    color: var(--footer-link-hover-color);
}

.footer-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
}

.contact-item a {
    color: var(--footer-link-color);
    text-decoration: none;
    transition: color 0.3s;
}

.contact-item a:hover {
    color: var(--footer-link-hover-color);
}


/* Light Theme */
:root {
    --footer-background-color: #007bff;
    --footer-text-color: #ebe3e3;
    --footer-icon-color: #ebe3e3;
    --footer-link-color: #ebe3e3;
    --footer-link-hover-color: #f1f1f1;
    --footer-input-background: #f1f1f1;
    --footer-input-border: #ccc;
    --footer-input-text: #ebe3e3;
}

/* Dark Theme */
.dark {
    --footer-background-color: #181818;
    --footer-text-color: #ffffff;
    --footer-icon-color: #ffffff;
    --footer-link-color: #ffffff;
    --footer-link-hover-color: #ffa500;
    --footer-input-background: #2c2c2c;
    --footer-input-border: #555;
    --footer-input-text: #ffffff;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-bottom p,
    .footer-bottom a {
        margin: 10px 0;
    }

    .footer-center {
        width: 100%;
        align-items: center;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
    }

    .footer-links ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .footer-links ul li a {
        text-align: center;
    }

    .footer-contact {
        flex-direction: column;
        gap: 10px;
    }

    .contact-item {
        text-align: center;
    }
        
    footer-contact .contact-item svg {
        font-size: 50px;
    }
    
    .footer-contact .contact-item p {
        font-size: 14px;
    }
}