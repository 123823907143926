.menu-icon {
    display: none;
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #000;
}

@media (max-width: 768px) {
    .menu-icon {
        display: block;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        color: #ffffff;
        z-index: 1001;
        padding: 20px;
        display: flex;
        flex-direction: column;
        transition: transform 0.3s ease-in-out;
        transform: translateX(-100%);
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .sidebar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    .sidebar-logo {
        width: 200px;
    }

    .sidebar-title {
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
        flex-grow: 1;
    }

    .close-icon {
        font-size: 24px;
        cursor: pointer;
        margin-left: 10px;
        color: #ffffff;
        display: none;
    }

    .sidebar.open .close-icon {
        display: inline;
    }
}

.sidebar {
    width: 250px;
    padding: 20px;
    background-color: #2c3e50;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    overflow-y: auto;
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.sidebar-logo {
    width: 200px;
}

.sidebar-title {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    flex-grow: 1;
}

.sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.sidebar-item {
    padding: 15px 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    border-radius: 8px;
}

.sidebar-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.sidebar-icon {
    margin-right: 10px;
}

.sidebar-section {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    color: gray;
    font-size: 14px;
}

/* Text color for active item */
.sidebar-item.active {
    color: #1770e4;
    font-weight: bold;
    font-size: 18px;
}

.sidebar.light {
    background-color: white;
    color: #90a2b2;
    /* color: #000000; */
}

.sidebar.light .sidebar-item:hover {
    background-color: #e2e6ea;
}

.sidebar.light .sidebar-section {
    color: #6c757d;
}

.sidebar.dark {
    background-color: #181818;
    color: #ffffff;
}

.sidebar.dark .sidebar-item:hover {
    background-color: #495057;
}

.sidebar.dark .sidebar-section {
    color: #adb5bd;
}



@media (min-width: 769px) {
    .close-icon {
        display: none;
    }
}