.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    z-index: 1000;
}

.email-input {
    padding: 10px 10px 10px 35px;
    border-radius: 6px;
    border: 1px solid #ccc;
    height: 35px;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    font-size: 18px;
    pointer-events: none;
}

.topbar-right {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 30px;
}

.topbar-icon {
    font-size: 20px;
    cursor: pointer;
}

.user-icon {
    font-size: 28px;
}

.dark-theme {
    background-color: #181818;
    color: white;
}

.light-theme {
    background-color: white;
    color: black;
}

@media (max-width: 768px) {
    .topbar {
        padding: 10px;
    }

    .topbar-right {
        gap: 15px;
    }

    .email-input {
        display: none;
    }
    .search-icon {
        display: none;
    }
}

.mobile-toggle {
    display: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .mobile-toggle {
        display: block;
    }
}